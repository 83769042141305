import { buildCollection } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface PlatformUser {
  displayName: string;
  email: string;
  iAmVariable: string;
  role: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
  goLiveDate?: Timestamp;
}

const platformUserCollection = buildCollection<PlatformUser>({
  group: 'Users',
  initialSort: ['email', 'desc'],
  path: 'platformUsers',
  name: 'Platform Users',
  singularName: 'User',
  inlineEditing: true,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin'),
    create: authController.extra?.roles.includes('admin'),
  }),
  properties: {
    displayName: {
      name: 'Name',
      dataType: 'string',
    },
    email: {
      name: 'Email',
      dataType: 'string',
      validation: { required: true },
    },
    iAmVariable: {
      name: 'I Am...',
      dataType: 'string',
    },
    role: {
      dataType: 'string',
      name: 'Role',
      description: 'Dictates what the user can see and do.',
      enumValues: {
        none: 'None',
        partnerApplicant: 'Partner Applicant',
        partner: 'Partner',
      },
    },
    utmSource: {
      name: 'UTM Source',
      dataType: 'string',
    },
    utmMedium: {
      name: 'UTM Medium',
      dataType: 'string',
    },
    utmCampaign: {
      name: 'UTM Campaign',
      dataType: 'string',
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    goLiveDate: {
      name: 'Go Live Date',
      dataType: 'date',
    },
  },
});

export default platformUserCollection;
