import { preDeleteFuncForItemsWithCommonImages, onSaveFuncForItemsWithCommonImages } from './callbackFuncs';
import { buildCollection, buildEntityCallbacks } from 'firecms';
import { Timestamp } from 'firebase/firestore';
import { categorizationEnumValues } from '../enums';

export interface Scholarship {  
  logo: string;
  orgName: string;
  location: string;
  name: string;
  deadline: any;
  amount: string;
  description: string;
  applyUrl: string;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
  goLiveDate?: Timestamp;
}

const scholarshipCallbacks = buildEntityCallbacks({  
  onPreDelete: preDeleteFuncForItemsWithCommonImages('scholarships'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImages('scholarships'),
});

const scholarshipsCollection = buildCollection<Scholarship>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'scholarships',
  name: 'Scholarships',
  callbacks: scholarshipCallbacks,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    logo: {
      name: 'Image',
      description: 'A thumbnail image.',
      dataType: 'string',
      storage: {
        storagePath: 'dynamicImages',
        acceptedFiles: ['image/*'],
        fileName: (context: any) => {
          return context.file.name;
        },
      },
    },
    orgName: {
      name: 'Org Name',
      dataType: 'string',
      validation: { required: true },
    },
    location: {
      name: 'Location',
      dataType: 'string',
      validation: { required: true },
    },
    name: {
      name: 'Name',
      dataType: 'string',
      validation: { required: true },
    },
    deadline: {
      name: 'Deadline',
      dataType: "date",      
    },
    amount: {
      name: 'Amount',
      dataType: 'string',      
    },
    description: {
      name: 'Description',
      dataType: 'string',
      markdown: true,
      validation: { required: true },
    },
    applyUrl: {
      name: 'Apply Url',
      dataType: 'string',
      validation: { required: true },
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    goLiveDate: {
      name: 'Go Live Date',
      dataType: 'date',
    },
  },
});

export default scholarshipsCollection;
