import { buildCollection } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export enum eUserRoles {
  admin = 'admin',
  associate = 'associate',
  applicant = 'applicant',
}

export interface CmsUser {
  role: eUserRoles;
  displayName: string;
  email: string;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
  goLiveDate?: Timestamp;
}

const userCollection = buildCollection<CmsUser>({
  group: 'Users',
  initialSort: ['dateUpdated', 'desc'],
  path: 'users',
  name: 'CMS Users',
  singularName: 'User',
  inlineEditing: true,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin'),
    delete: authController.extra?.roles.includes('admin'),
    create: authController.extra?.roles.includes('admin'),
  }),
  properties: {
    displayName: {
      name: 'Name',
      dataType: 'string',
    },
    email: {
      name: 'Email',
      dataType: 'string',
      validation: { required: true },
    },
    role: {
      dataType: 'string',
      name: 'Role',
      description: 'Dictates what the user can see and do.',
      enumValues: {
        admin: 'Admin',
        associate: 'Associate',
        applicant: 'Applicant',
      },
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    goLiveDate: {
      name: 'Go Live Date',
      dataType: 'date',
    },
  },
});

export default userCollection;
