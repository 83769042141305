import { buildCollection } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface Question {  
  email: string;    
  question: string;        
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
  goLiveDate?: Timestamp;
}

const questionsCollection = buildCollection<Question>({
  group: 'Data',
  initialSort: ['dateCreated', 'desc'],
  path: 'questionsAndFeedback',
  name: 'Questions And Feedback',
  singularName: 'Question / Feedback',
  inlineEditing: true,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),    
  }),
  properties: {
    email: {
      name: 'Email',
      dataType: 'string',      
    },    
    question: {
      name: 'Question',
      dataType: 'string',      
    },              
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: false,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    goLiveDate: {
      name: 'Go Live Date',
      dataType: 'date',
    },
  },
});

export default questionsCollection;
