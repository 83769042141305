import { buildCollection } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface Resource {
  tags: string[];
  title: string;
  description: string;
  files: string[]; // File urls.
  links: string[];
  visible: boolean;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
  goLiveDate?: Timestamp;
}

const resourcesCollection = buildCollection<Resource>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'resources',
  name: 'Resources',
  singularName: 'Resource',
  inlineEditing: false,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    tags: {
      dataType: 'array',
      name: 'Tags',
      description: 'Used for categorization',
      of: {
        dataType: 'string',
        enumValues: {          
          link: 'Link',
          academic: 'Academic',
          printable: 'Printable',
          toolkit: 'Toolkit',
          townhall: 'TownHall',
          research: 'Research',
          everfi: 'EVERFI',
        },
      },
    },
    title: {
      name: 'Title',
      dataType: 'string',
      validation: { required: true },
    },
    description: {
      name: 'Description',
      dataType: 'string',      
      markdown: true,
    },
    files: {
      dataType: 'array',
      name: 'Files',
      of: {
        dataType: 'map',
        properties: {          
          label: {
            validation: { required: true },
            name: 'label',
            dataType: 'string',
          },
          file: {
            validation: { required: true },
            name: 'file',
            dataType: 'string',
            storage: {
              storagePath: 'resourceFiles',
              fileName: (context: any) => {
                return context.file.name;
              },
            },
          },
        },
      },
    },
    links: {
      dataType: 'array',
      name: 'Links',
      of: {
        dataType: 'map',
        properties: {
          label: {
            validation: { required: true },
            name: 'label',
            dataType: 'string',
          },
          link: {
            validation: { required: true },
            name: 'link',
            dataType: 'string',
          },
        },
      },
    },
    visible: {
      name: 'Visible',
      dataType: 'boolean',
      description: 'Should this resource be visible?',
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    goLiveDate: {
      name: 'Go Live Date',
      dataType: 'date',
    },
  },
});

export default resourcesCollection;
