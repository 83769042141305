import { preDeleteFuncForItemsWithCommonImages, onSaveFuncForItemsWithCommonImages } from './callbackFuncs';
import { buildCollection, buildEntityCallbacks } from 'firecms';
import { Timestamp } from 'firebase/firestore';
import { categorizationEnumValues } from '../enums';

export interface Quote {
  tags: string[];
  image: string;
  secondaryImage: string;
  title: string;  
  quotes: string[];
  author: string;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
  goLiveDate?: Timestamp;
}

const quoteCallbacks = buildEntityCallbacks({  
  onPreDelete: preDeleteFuncForItemsWithCommonImages('quotes'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImages('quotes'),
});

const quotesCollection = buildCollection<Quote>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'quotes',
  name: 'Quotes',
  callbacks: quoteCallbacks,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    tags: {
      dataType: 'array',
      name: 'Tags',
      description: 'Used for categorization',
      of: {
        dataType: 'string',
        enumValues: {
          ...categorizationEnumValues,
        },
      },
    },
    image: {
      name: 'Image',
      description: 'A thumbnail image.',
      dataType: 'string',
      storage: {
        storagePath: 'dynamicImages',
        acceptedFiles: ['image/*'],
        fileName: (context: any) => {
          return context.file.name;
        },
      },
    },
    secondaryImage: {
      name: 'Secondary Image',
      description: 'An image to occupy the second slide.',
      dataType: 'string',
      storage: {
        storagePath: 'dynamicImages',
        acceptedFiles: ['image/*'],
        fileName: (context: any) => {
          return context.file.name;
        },
      },
    },    
    title: {
      name: 'Title',
      dataType: 'string',
      validation: { required: true },
    },    
    quotes: {
      dataType: 'array',
      name: 'Quotes',
      description: 'Include more than one quote if the entire quote is too large for one slide and you want to break it up into multiple slides on a scrollable carousel',
      of: {
        dataType: 'string',
      },      
    },
    author: {
      name: 'Author',
      dataType: 'string',      
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    goLiveDate: {
      name: 'Go Live Date',
      dataType: 'date',
    },
  },
});

export default quotesCollection;
